import { base_user, list_user } from '../Utilities/User'
import { all_base } from '../Utilities/Base';
import { liste_crea } from './Crea';
import { liste_router } from './Router';
import { list_tags, list_client, list_country, list_model } from './Stats';
import { base64_encode, base64_decode } from './Utilities';

export async function getMainData(user_id, apikey,stats_apikey,limit=20, all_user=false, all_base_list=true, all_base_user=true, all_router=true, all_tags=true,all_clients=true,all_country=true,all_models=true, crea=false, onlyme) {
    var result = {}
    //all_user all_user
    try {
        if(all_user){
            if(localStorage.getItem(base64_encode('all_user')) !== null){
                result['all_user'] = JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_user'))))
            }else{
                await Promise.resolve(list_user(user_id, apikey)).then((value) => {
                    let field = 'username'
                    var list_all_user = (value).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
                    result['all_user'] = list_all_user
                });
            }
        }
    }
    catch (err) {
        console.error('error on getting all user ', err);
        result['all_user'] = []
    }
    //all_base
    try {
        if(all_base_list){
            if(localStorage.getItem(base64_encode('all_base')) !== null){
                result['all_base'] = JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_base'))))
            }else{
                await Promise.resolve(all_base(user_id, apikey)).then((value) => {
                    let field = 'basename'
                    var list_base_all = (value).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
                    result['all_base'] = list_base_all
                });
            }
        }
    }
    catch (err) {
        console.error('error on getting all database ', err);
        result['all_base'] = []
    }

    // //all_base_user
    try {
        if(all_base_user){
            if(localStorage.getItem(base64_encode('base_user')) !== null){
                result['base_user'] = JSON.parse(base64_decode(localStorage.getItem(base64_encode('base_user'))))
            }else{
                await Promise.resolve(base_user(user_id, apikey)).then((value) => {
                    let field = 'basename'
                    var list_base_user = (value).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
                    result['base_user'] = list_base_user
                });
            }
        }
    }
    catch (err) {
        console.error('error on getting database user ', err);
        result['base_user'] = []
    }
    
    // //all_router
    try {
        if(all_router){
            if(localStorage.getItem(base64_encode('all_router')) !== null){
                result['all_router'] = JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_router'))))
            }else{
                await Promise.resolve(liste_router(user_id, apikey)).then((value) => {
                    let field = 'name'
                    var list_router = (value).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
                    result['all_router'] = list_router
                });
            }
        }
    }
    catch (err) {
        console.error('error on getting all router ', err);
        result['all_router'] = []
    }
    // //all_tags 
    try {
        if(all_tags){
            if(localStorage.getItem(base64_encode('tags')) !== null){
                result['all_tags'] = JSON.parse(base64_decode(localStorage.getItem(base64_encode('tags'))))
            }else{
                await Promise.resolve(list_tags(user_id, apikey, stats_apikey)).then((value) => {
                    let field = 'tag'
                    var list_user = (value).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
                    result['all_tags'] = list_user
                });
            }
        }
    }
    catch (err) {
        console.error('error on getting all tags ', err);
        result['all_tags'] = []
    }

    //all_clients 
    try {
        if(all_clients){
            if(localStorage.getItem(base64_encode('Stats_clients')) !== null){
                result['all_clients'] = JSON.parse(base64_decode(localStorage.getItem(base64_encode('Stats_clients'))))
            }else{
                await Promise.resolve(list_client(user_id, apikey, stats_apikey)).then((value) => {
                    let field = 'name'
                    var list_clients = (value).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
                    result['all_clients'] = list_clients
                });
            }
        }
    }
    catch (err) {
        console.error('error on getting all clients ', err);
        result['all_clients'] = []
    }

    //all_country
    try {
        if(all_country){
            if(localStorage.getItem(base64_encode('Stats_country_and_currency')) !== null){
                result['all_country'] = JSON.parse(base64_decode(localStorage.getItem(base64_encode('Stats_country_and_currency'))))
            }else{
                await Promise.resolve(list_country(user_id, apikey,stats_apikey)).then((value) => {
                    let field = 'countrycode'
                    var list_country = (value).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
                    result['all_country'] = list_country
                });
            }
        }
    }
    catch (err) {
        console.error('error on getting all country ', err);
        result['all_country'] = []
    }

    //all_models
    try {
        if(all_models){
            if(localStorage.getItem(base64_encode('stats_model')) !== null){
                result['all_models'] = JSON.parse(base64_decode(localStorage.getItem(base64_encode('stats_model'))))
            }else{
                await Promise.resolve(list_model(user_id, apikey,stats_apikey)).then((value) => {
                    let field = 'name'
                    var list_models = (value).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
                    result['all_models'] = list_models
                });
            }
        }
    }
    catch (err) {
        console.error('error on getting all models ', err);
        result['all_models'] = []
    }

    //all_creativities
    try {
        var filtre = 1
        if(onlyme === false){
            filtre = 0
        }
        if(crea){
            await Promise.resolve(liste_crea(user_id, apikey,limit,filtre)).then((value) => {
                result['all_crea'] = value
            });
        }
    }
    catch (err) {
        console.error('error on getting all models ', err);
        result['all_crea'] = []
    }



    return result
}