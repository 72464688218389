import React, { Component } from 'react';
import { base64_decode, base64_encode, generateKey } from '../../Utilities/Utilities';
import { AutoComplete, Col, Row, Select, Input, Tooltip, DatePicker, Button, message } from 'antd';
import { list_advertiser, list_client, list_model, list_segment, save_stats } from '../../Utilities/Stats';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select
const dateFormat = "YYYY-MM-DD HH:mm:ss";
class StatsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            //liste
            liste_advertiser: [],
            liste_client: [],
            liste_affiliate: [],
            liste_country: [],
            liste_currency: [],
            liste_tags: [],
            liste_models: [],
            liste_segments: [],
            //field
            crea_id: null,
            crea_stats_v1_id: null,
            crea_stats_v2_id: null,
            crea_base_id: null,
            crea_country_id: null,
            crea_currency_id: null,
            crea_advername: null,
            crea_advid: null,
            crea_clientid: null,
            crea_affid: null,
            crea_tags_id: null,
            crea_stats_revenu: [{ "modelid": null, "modelname": "", "payout": "", "coment": "" }],
            crea_comment: null,
            crea_segment: [],
            crea_creativity: null,
            crea_status: null,
            crea_brand: null,
            crea_type: null,
            crea_date: null,
            crea_available_from: null,
            crea_available_until: null,
            crea_router_id: null,
            router: null,
            crea_stats_base_id: null,
            crea_isb2c: null,
            //loading
            loadingUpdateStats: false,
            loadingSendStats: false,
            displayUpdateBtn: 'none',
            displaySendBtn: 'none',


        }

    }

    componentDidMount() {
        var crea = this.props.creativities
        console.log(crea)
        this.ongetListSegments(crea['base_id'])
        this.getListModels()
        this.getListCurrency(this.props.liste_country)
        this.getListClientAndAffiliate(crea['clientid'])
        this.getListAdvertiser(crea['advertiser'])
        this.setState({
            crea_id: crea['id'],
            crea_base_id: crea['base_id'],
            crea_stats_v1_id: crea['stats_id'],
            crea_stats_v2_id: crea['focus_id'],
            liste_tags: this.props.liste_tags,
            liste_country: this.props.liste_country,
            crea_advername: crea['advertiser'],
            crea_advid: crea['advid'],
            crea_affid: parseInt(crea['affid']),
            crea_tags_id: parseInt(crea['tags_id']),
            crea_country_id: parseInt(crea['stats_countryid']),
            crea_currency_id: parseInt(crea['stats_currencyid']),
            crea_stats_revenu: JSON.parse(crea['stats_model']),
            crea_comment: crea['stats_comment'] !== "null" ? crea['stats_comment'] : null,
            crea_segment: crea['stats_segment'] !== "[]" ? JSON.parse(crea['stats_segment']) : [],
            crea_creativity: crea['creativity'],
            crea_status: crea['stats_status'],
            crea_brand: crea['brand'],
            crea_type: crea['stats_schedule_type'],
            crea_date: moment(crea['stats_date']),
            crea_router_id: parseInt(crea['router_id']) !== 0 ? crea['router_id'] : 0,
            crea_available_from: moment(crea['stats_available_from']),
            crea_available_until: moment(crea['stats_available_until']),
            router: crea['router'],
            crea_stats_base_id: crea['stats_base_id'],
            crea_isb2c: parseInt(crea['isb2c']) === 1 ? parseInt(crea['isb2c']): 0,
            displayUpdateBtn: crea['focus_id'] !== null && crea['focus_id'] !== "0" ? "block": "none",
            displaySendBtn: crea['focus_id'] === null || crea['focus_id'] === "0" ? "block": "none"
        })
    }

    getListAdvertiser(advertiser) {
        Promise.resolve(list_advertiser(this.state.user_id, this.state.apikey, this.state.stats_apikey, advertiser)).then((value) => {
            this.setState({ liste_advertiser: value })
        });
    }

    onAdvertiserChange(value) {
        try {
            this.setState({ crea_advername: value })
            if (value.length < 3) {
                return null
            } else {
                this.getListAdvertiser(value)
            }
        } catch (error) {
            console.log('errror on advertiser change ' + error)
            return
        }

    }

    getListClientAndAffiliate(clientid) {
        Promise.resolve(list_client(this.state.user_id, this.state.apikey, this.state.stats_apikey)).then((value) => {
            let liste_aff = []
            value.map(item => {
                if (parseInt(item['id']) === parseInt(clientid)) {
                    liste_aff = item['affiliatemanagers']
                }
            })
            this.setState({ liste_client: value, liste_affiliate: liste_aff, crea_clientid: parseInt(clientid) })
        });
    }

    onClientsChange(value) {
        this.setState({
            liste_affiliate: [],
            crea_affid: null
        })
        try {
            var liste_aff = []
            this.state.liste_client.map(item => {
                if (parseInt(value) === parseInt(item['id'])) {
                    liste_aff = item['affiliatemanagers']
                }
                return null
            })
            this.setState({
                liste_affiliate: liste_aff,
                crea_clientid: value,
            })
        } catch (error) {
            console.log('error on client change ' + error)
        }
    }

    getListCurrency(liste_country) {
        const objetsUniquesParId = {};
        liste_country.forEach((objet) => {
            const { currencyid } = objet;
            if (!objetsUniquesParId[currencyid]) {
                objetsUniquesParId[currencyid] = objet;
            }
        });
        const tableauUnique = Object.values(objetsUniquesParId);
        this.setState({
            liste_currency: tableauUnique
        })
    }

    onModelChange(modelid, modelname, i) {
        var old = this.state.crea_stats_revenu
        old[i]['modelid'] = modelid
        old[i]['modelname'] = modelname
        this.setState({
            crea_stats_revenu: old
        })
    }

    onPayoutChange(payout, i) {
        var old = this.state.crea_stats_revenu
        old[i]['payout'] = payout
        this.setState({
            crea_stats_revenu: old
        })
    }

    onAddStatsRange() {
        var old = this.state.crea_stats_revenu
        old.push({ "modelid": null, "modelname": "", "payout": "" })
        this.setState({
            crea_stats_revenu: old
        })
    }

    onRemoveStatsRange(index) {
        var old = this.state.crea_stats_revenu
        old.splice(index, 1)
        console.log(old)
        this.setState({
            crea_stats_revenu: old
        })
    }

    renderStatsPayout() {
        return this.state.crea_stats_revenu && this.state.crea_stats_revenu.map((el, i) =>
            <Row key={i}>
                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                    <p>Model(*)</p>
                </Col>
                <Col span={8}>
                    <Select
                        size="medium"
                        showSearch
                        optionFilterProp="children"
                        style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                        value={el['modelid']}
                        onChange={
                            (value) => {
                                this.state.liste_models.map(model => {
                                    if (parseInt(model['id']) === parseInt(value)) {
                                        this.onModelChange(value, model['name'], i)
                                    }
                                    return null
                                })
                            }
                        }
                    >
                        {
                            this.state.liste_models && this.state.liste_models.map(item => {
                                var key = generateKey()
                                return (
                                    <Option key={key} value={item['id']}>{(item['name']).toUpperCase()}</Option>
                                )
                            })
                        }
                    </Select>
                </Col>
                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                    <p>Payout(*)</p>
                </Col>
                <Col span={1}>
                    <Input
                        size="medium"
                        value={el['payout']}
                        style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                        onChange={(e) => {
                            this.onPayoutChange(e.target.value, i)
                        }}
                    />
                </Col>
                <Col span={2} style={{ padding: 3, textAlign: 'center' }}>
                    <p>Comment</p>
                </Col>
                <Col span={5}>
                    <Input
                        size="medium"
                        value={el['coment']}
                        style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                        onChange={(e) => {
                            this.onPayoutChange(e.target.value, i)
                        }}
                    />
                </Col>
                {
                    i === 0 && <Col span={2} style={{ paddingLeft: 13 }}>
                        <Tooltip title="Add rows" placement="bottom">
                            <PlusCircleOutlined
                                style={{ fontSize: 20 }}
                                onClick={() => {
                                    this.onAddStatsRange()
                                }}
                            />
                        </Tooltip>
                    </Col>
                }
                {
                    i !== 0 && <Col span={2} style={{ paddingLeft: 13 }}>
                        <Tooltip title="Remove rows" placement="bottom">
                            <MinusCircleOutlined
                                style={{ fontSize: 20 }}
                                onClick={() => {
                                    this.onRemoveStatsRange(i)
                                }}
                            />
                        </Tooltip>
                    </Col>
                }
            </Row>

        )
    }

    getListModels() {
        Promise.resolve(list_model(this.state.user_id, this.state.apikey, this.state.stats_apikey)).then((value) => {
            this.setState({ liste_models: value })
        });
    }

    ongetListSegments(base_id) {
        Promise.resolve(list_segment(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id)).then((value) => {
            var mydata = value.sort((a, b) => b.id - a.id);
            this.setState({
                liste_segments: mydata
            })
        });
    }

    onSaveToStats(action) {
        if(action === "insert"){this.setState({loadingSaveStats: true})}else{this.setState({loadingUpdateStats: true})}
        var advid = null
        this.state.liste_advertiser.map(item => {
            if (item['name'] === this.state.crea_advername) {
                advid = item['id']
            }
            return null
        })
        var country_name = null
        var currency_name = null
        this.state.liste_country.map(item => {
			if (parseInt(this.state.crea_country_id) === parseInt(item['id'])) {
				country_name = item['countrycode']
			}
			if (parseInt(this.state.crea_currency_id) === parseInt(item['currencyid'])) {
				currency_name = item['currencyname']
			}
			return null
		})
        var client_name = null
		var affiliate_name = null
        this.state.liste_client.map(item => {
			if (parseInt(this.state.crea_clientid) === parseInt(item['id'])) {
				client_name = item['name']
			}
		})
		this.state.liste_affiliate.map(item => {
			if (parseInt(this.state.crea_affid) === parseInt(item['id'])) {
				affiliate_name = item['name']
			}
		})
		var tagsname = null
        this.state.liste_tags.map(item => {
            if (parseInt(this.state.crea_tags_id) === item['id']) {
                tagsname = item['tag']
            }
        })
        var segment_name = []
        console.log(this.state.crea_segment)
        this.state.liste_segments.map(item => {
            this.state.crea_segment.map(elem => {
                if(parseInt(elem) === parseInt(item['id'])){
                    segment_name.push(item['name'])
                }
            })
        })
        var seg = segment_name.join('-')
        if (advid === null || this.state.crea_router_id === null || this.state.crea_router_id === "0") {
			if (advid === null) { message.info('ADVERTISER MUST BE SELECTED FROM THE LIST !!!', 2) }
			if (this.state.crea_router_id === null || this.state.crea_router_id === "0") { message.info('ROUTER ID IS NOT VALID !!!', 2) }
			this.setState({ loadingSaveStats: false,loadingUpdateStats: false })
			return null
		}else{
            var mydata = {
                "user_id": this.state.user_id,
                "apikey": this.state.apikey,
                "stats_apikey": this.state.stats_apikey,
                "country_id": this.state.crea_country_id,
                "country_name": country_name,
                "currency_id": this.state.crea_currency_id,
                "currency_name": currency_name,
                "advname": this.state.crea_advername,
                "advid": advid,
                "client_id": this.state.crea_clientid,
                "client_name": client_name,
                "aff_id": this.state.crea_affid,
                "aff_name": affiliate_name,
                "tags_id" : this.state.crea_tags_id,
                "tagsname": tagsname,
                "stats_revenu": JSON.stringify(this.state.crea_stats_revenu),
                "comment": this.state.crea_comment,
                "segment": JSON.stringify(this.state.crea_segment),
                "creativity": this.state.crea_creativity,
                "bat_status": this.state.crea_status,
                "brand" : this.state.crea_brand,
                "schedule_type": this.state.crea_type,
                "crea_date": moment(this.state.crea_date).format(dateFormat).toString(),
                "campagn_from": moment(this.state.crea_available_from).format(dateFormat).toString(),
                "campagn_until": moment(this.state.crea_available_until).format(dateFormat).toString(),
                "router_id": this.state.crea_router_id,
                "action": action,
                "crea_id": this.state.crea_id,
                "id_v1": this.state.crea_stats_v1_id,
                "id_v2": this.state.crea_stats_v2_id,
                "base_id": this.state.crea_base_id,
                "router": this.state.router,
                "stats_base_id": this.state.crea_stats_base_id,
                "crea_isb2c": this.state.crea_isb2c,
                "segment_name": seg
            }
            Promise.resolve(save_stats(mydata)).then((value) => {
                value['status'] === "success" ? message.success(value['description'], 3) :  message.warning(value['description'], 3)
                this.setState({ loadingSaveStats: false,loadingUpdateStats: false })
            });
        }
	}

    render() {
        return (
            <div style={{ height: '100%', width: '100%', overflow: 'auto'}}>
                <div style={{ width: '80%', margin: '0 auto', height: '89vh', padding: 15, marginTop: 30 }}>
                    <Row>
                        <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                            <span>Country</span>
                        </Col>
                        <Col span={8}>
                            <Select
                                size="medium"
                                showSearch
                                optionFilterProp="children"
                                style={{ textAlign: 'center', width: '100%', color: '#192a56', fontWeight: 700 }}
                                value={this.state.crea_country_id}
                                onChange={(value) => {
                                    this.setState({ crea_country_id: value })
                                }}
                            >
                                {
                                    this.state.liste_country && this.state.liste_country.map(item => {
                                        var key = generateKey()
                                        return (
                                            <Option key={key} value={item.id}>{item.countrycode}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                        <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                            <span>Currency</span>
                        </Col>
                        <Col span={8}>
                            <Select
                                size="medium"
                                showSearch
                                optionFilterProp="children"
                                style={{ textAlign: 'center', width: '100%', color: '#192a56', fontWeight: 700 }}
                                value={this.state.crea_currency_id}
                                onChange={(value) => {
                                    this.setState({ crea_currency_id: value })
                                }}
                            >
                                {
                                    this.state.liste_currency && this.state.liste_currency.map(item => {
                                        var key = generateKey()
                                        return (
                                            <Option key={key} value={item.currencyid}>{item.currencyname}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                            <span>Advertiser</span>
                        </Col>
                        <Col span={8}>
                            <AutoComplete
                                size="medium"
                                style={{ textAlign: 'center', fontSize: 16, fontFamily: 'Lato', width: '100%', color: '#192a56', fontWeight: 800 }}
                                value={this.state.crea_advername}
                                defaultValue={this.state.crea_advername}
                                onChange={
                                    (value) => {
                                        this.onAdvertiserChange(value)
                                    }
                                }
                            >
                                {
                                    this.state.liste_advertiser && this.state.liste_advertiser.map(item => {
                                        var key = generateKey()
                                        return (
                                            <Option key={key} value={item.name}>{item.name}</Option>
                                        )
                                    })
                                }
                            </AutoComplete>
                        </Col>
                        <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                            <span>Client</span>
                        </Col>
                        <Col span={8}>
                            <Select
                                size="medium"
                                showSearch
                                optionFilterProp="children"
                                style={{ textAlign: 'center', width: '100%', color: '#192a56', fontWeight: 700 }}
                                value={this.state.crea_clientid}
                                onChange={(value) => {
                                    this.onClientsChange(value)
                                }}
                            >
                                {
                                    this.state.liste_client && this.state.liste_client.map(item => {
                                        var key = generateKey()
                                        return (
                                            <Option key={key} value={item.id}>{item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                            <span>Affiliate</span>
                        </Col>
                        <Col span={8}>
                            <Select
                                size="medium"
                                showSearch
                                optionFilterProp="children"
                                style={{ textAlign: 'center', width: '100%', color: '#192a56', fontWeight: 700 }}
                                value={this.state.crea_affid}
                                onChange={(value) => {
                                    this.setState({ crea_affid: value })
                                }}
                            >
                                {
                                    this.state.liste_affiliate && this.state.liste_affiliate.map(item => {
                                        var key = generateKey()
                                        return (
                                            <Option key={key} value={parseInt(item.id)}>{item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                        <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                            <span>Tags</span>
                        </Col>
                        <Col span={8}>
                            <Select
                                size="medium"
                                showSearch
                                optionFilterProp="children"
                                style={{ textAlign: 'center', width: '100%', color: '#192a56', fontWeight: 700 }}
                                value={this.state.crea_tags_id}
                                onChange={(value) => {
                                    this.setState({ crea_tags_id: value })
                                }}
                            >
                                {
                                    this.state.liste_tags && this.state.liste_tags.map(item => {
                                        var key = generateKey()
                                        return (
                                            <Option key={key} value={item.id}>{item.tag}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>
                    <br />
                    {this.renderStatsPayout()}
                    <br />
                    <Row>
                        <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                            <p>Comment</p>
                        </Col>
                        <Col span={8}>
                            <Input
                                value={this.state.crea_comment}
                                size="medium"
                                style={{ width: '100%', color: '#192a56', fontWeight: 700 }}
                                onChange={(e) => { this.setState({ crea_comment: e.target.value }) }}
                            />
                        </Col>
                        <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                            <p>Segment</p>
                        </Col>
                        <Col span={8}>
                            <Select
                                mode="multiple"
                                optionFilterProp="children"
                                showSearch
                                size="medium"
                                style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
                                value={this.state.crea_segment}
                                onChange={(value) => {
                                    this.setState({ crea_segment: value })
                                }}
                            >
                                {
                                    this.state.liste_segments && this.state.liste_segments.map(item => {
                                        var key = generateKey()
                                        return (
                                            <Option key={key} value={parseInt(item.id)}>{item.id + ' - ' + item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                            <p>Campaign Name</p>
                        </Col>
                        <Col span={8}>
                            <Input
                                value={this.state.crea_creativity}
                                size="medium"
                                style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                onChange={(e) => { this.setState({ crea_creativity: e.target.value }) }}
                            />
                        </Col>
                        <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                            <p>Status</p>
                        </Col>
                        <Col span={8}>
                            <Select
                                size="medium"
                                showSearch
                                optionFilterProp="children"
                                style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                value={this.state.crea_status}
                                onChange={(value) => {
                                    this.setState({
                                        crea_status: value
                                    })
                                }}
                            >
                                <Option key="1" value="1">BAT not send</Option>
                                <Option key="2" value="2">BAT sent, no approved</Option>
                                <Option key="3" value="3">BAT approved</Option>
                                <Option key="4" value="4">Campaign scheduled</Option>
                                <Option key="5" value="5">Campaign sent</Option>
                                <Option key="6" value="6">Campaign billed</Option>
                                <Option key="7" value="7">Campaign Cancelled</Option>
                            </Select>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                            <p>Brand</p>
                        </Col>
                        <Col span={8}>
                            <Input
                                value={this.state.crea_brand}
                                size="medium"
                                style={{ width: '100%', color: '#192a56', fontWeight: 700 }}
                                onChange={(e) => { this.setState({ crea_brand: e.target.value }) }}
                            />
                        </Col>

                        <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                            <p>Scheduled type</p>
                        </Col>
                        <Col span={8}>
                            <Select
                                size="medium"
                                showSearch
                                optionFilterProp="children"
                                style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                value={this.state.crea_type}
                                onChange={(value) => {
                                    this.setState({
                                        crea_stats_type: value
                                    })
                                }}
                            >
                                <Option value="1">Normal</Option>
                                <Option value="2">Split Test</Option>
                                <Option value="3">ES - Split Test Single Message</Option>
                                <Option value="4">Multiple Shoots</Option>
                            </Select>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                            <p>Date Scheduled</p>
                        </Col>
                        <Col span={8}>
                            <DatePicker
                                format={dateFormat}
                                style={{ width: '100%', textAlign: 'center' }}
                                size="medium"
                                value={this.state.crea_date}
                                showTime
                                disabledDate={current => {
                                    return current && current <= moment().subtract(1, 'days');
                                }}
                                onChange={(a, b) => {
                                    this.setState({
                                        crea_date: a,
                                        crea_available_from: a
                                    })
                                }}

                            />
                        </Col>
                        <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                            <p>Sendout ID</p>
                        </Col>
                        <Col span={8}>
                            <Input
                                value={this.state.crea_router_id}
                                size="medium"
                                style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                onChange={(e) => { this.setState({ crea_router_id: e.target.value }) }}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                            <p>Available from</p>
                        </Col>
                        <Col span={8}>
                            <DatePicker
                                format={dateFormat}
                                style={{ width: '100%', textAlign: 'center' }}
                                size="medium"
                                showTime
                                value={this.state.crea_available_from}
                                onChange={(a, b) => {
                                    this.setState({
                                        crea_available_from: a
                                    })
                                }}
                            />
                        </Col>
                        <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                            <p>Available until</p>
                        </Col>
                        <Col span={8}>
                            <DatePicker
                                format={dateFormat}
                                style={{ width: '100%', textAlign: 'center' }}
                                showTime
                                size="medium"
                                value={this.state.crea_available_until}
                                onChange={(a, b) => {
                                    this.setState({
                                        crea_available_until: a
                                    })
                                }}
                                disabledDate={current => {
                                    return current && current <= moment().subtract(1, 'days');
                                }}
                            />
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col span={24} style={{ textAlign: 'center', display: `${this.state.displayUpdateBtn}` }}>
                            <Button
                                loading={this.state.loadingUpdateStats}
                                className="btn btn-primary"
                                style={{ width: '64%', height: 50, fontSize: 15 }}
                                onClick={() => { this.onSaveToStats("update") }}
                            >
                                UPDATE STATS DATA
                            </Button>
                        </Col>
                        <Col span={24} style={{ textAlign: 'center', display: `${this.state.displaySendBtn}` }}>
                            <Button
                                loading={this.state.loadingSendStats}
                                className="btn btn-success"
                                style={{ width: '64%', height: 50, fontSize: 15 }}
                                onClick={() => { this.onSaveToStats("insert") }}
                            >
                                SEND TO STATS
                            </Button>
                        </Col>
                        <Col span={5}></Col>
                    </Row>
                </div>
            </div>
        );
    }
}



export default StatsDetails;