import React, {useState, useEffect} from 'react'

export default function Status(props) {
    const [text, setText] = useState(props.status);
    const [color, setColor] = useState('');
    const [trigger, setTrigger] = useState('')
    const [color_trigger, setColor_trigger] = useState('');
    const bat_status = {"1":"Bat not send", "2": "Bat sent, not approved","3":"Bat approved","4":"Campaign scheduled", "5":"Campaign sent","6":"Campaign billed","7":"Campaign cancelled"}

    useEffect(() => {
        setText(props.status)
        switch (props.status) {
            case 'Optimizing':
                setColor('#0069d9')
                break;
            default:
                setColor('#f0932b')
                break;
        }
       if(props.status === 'Optimizing' || props.status === 'Processing ...'){
           setText('Processing')
           setColor('#0069d9')
       }else{
           if(props.status === 'Optimized'){
               setText('Optimized')
               setColor('#1B999F')
               if(props.trigger === "0"){
                switch (parseInt(props.bat)) {
                    case 1:
                        setColor_trigger('#0069d9')
                        setTrigger('(BAT NOT SEND)')
                        break;
                    case 2:
                        setColor_trigger('#FF8888')
                        setTrigger('(Bat sent, not approved)')
                        break;
                    case 3:
                        setColor_trigger('#1B999F')
                        setTrigger('(Bat approved)')
                        break;
                    case 4:
                        setColor_trigger('#D9D291')
                        setTrigger('(Campaign scheduled)')
                        break;
                    case 5:
                        setColor_trigger('#D9D291')
                        setTrigger('(Campaign sent)')
                        break;
                    case 6:
                        setColor_trigger('#D9D291')
                        setTrigger('(Campaign billed)')
                        break;
                    case 7:
                        setColor_trigger('#D9D291')
                        setTrigger('(Campaign cancelled)')
                        break;
                    default:
                        // setColor('#f0932b')
                        break;
                }
               }else{
                setText('Optimized')
                setColor('#1B999F')
                setTrigger('(CLICK-TO-CALL)')
                setColor_trigger('#C80052')
               }
                
           }else{

           }
       }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  return (
    <div>
        <span style={{color: `${color}`, fontWeight: 700, fontSize: 14}}>{text}</span><br/><span style={{color: `${color_trigger}`, fontWeight: 700, fontSize: 11}}>{trigger}</span>
    </div>
  )
}
