import { Col, message, Row } from 'antd';
import React, { Component } from 'react';
import ktklogo from '../../images/logo.png'
import loading from '../../images/loading3.gif'
import { Redirect } from 'react-router-dom'
import { base64_encode } from './Utilities';

class Cache extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        isClear: false,
        
      }
    }

    componentDidMount() {
        const cachekeep = [base64_encode("user_id"), base64_encode("apikey"), base64_encode("stats_apikey"), base64_encode("endpoint"), base64_encode("roles"), base64_encode("avatar"), base64_encode("username")]
        // Récupération des valeurs correspondantes aux clés à conserver
        const valuesToKeep = {};
        cachekeep.forEach((key) => {
            valuesToKeep[key] = localStorage.getItem(key);
        });
        console.log(valuesToKeep)
        localStorage.clear()
        Object.entries(valuesToKeep).forEach(([key, value]) => {
            localStorage.setItem(key, value);
        });
          setTimeout(() => {
            message.success('APPLICATION CACHE SUCCESSFULLY CLEAR !!!', 2)
            localStorage.setItem('defaulttabcrea', '3')
            this.setState({isClear: true})
        }, 3000)
    }


    render() {
        if (this.state.isClear === true) {
            return (<Redirect to={{
                pathname: '/app/creativities/task'
            }} />)
        }
        return (
            <div style={{height: '100vh'}}>
                
                <div style={{textAlign: 'center', height: '60vh', width: '50%', margin: '0 auto', padding: 10}}>
                    <img src={loading} alt="loading" style={{height: '40vh'}}/>
                    <h1><strong>Konticrea application cache will be clear ...</strong></h1>
                </div>
            </div>
        );
    }
}


export default Cache;