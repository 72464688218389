import React, { Component } from 'react';
import { Col, Row, Tag, Tabs, Select, Checkbox, Button, Collapse, message, Input, AutoComplete, DatePicker, Tooltip, Dropdown, Menu } from 'antd'
import Html from './details/Html';
import { base64_decode, base64_encode } from '../Utilities/Utilities';
import { getMainData } from '../Utilities/GetMainData';
import { Redirect } from 'react-router-dom'
import back from '../../images/back.png'
import { detail_crea } from '../Utilities/Crea';
import RouterDetails from './details/RouterDetails';

import focus from '../../images/focus.jpg'
import routeur from '../../images/routeur.jpg'
import html from '../../images/html.png'
import StatsDetails from './details/StatsDetails';

const { TabPane } = Tabs;
class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            goBack: false,
            crea_id: this.props.match.params.crea_id,
            // liste to use 
            liste_user: [],
            liste_tags: [],
            liste_clients: [],
            liste_advertiser: [],
            liste_country: [],
            liste_models: [],
            liste_base_user: [],
            // creativities
            crea_details: [],
            crea_status: false
        }

    }

    componentDidMount() {
        this.onGetCreaDetail()
        this.getData()
    }

    getData() {
        Promise.resolve(getMainData(this.state.user_id, this.state.apikey, this.state.stats_apikey, 0, true, false, true, false, true, true, true, true, false)).then((value) => {
            this.setState({
                liste_user: value['all_user'],
                liste_tags: value['all_tags'],
                liste_clients: value['all_clients'],
                liste_advertiser: value[''],
                liste_country: value['all_country'],
                liste_models: value['all_models'],
                liste_base_user: value['base_user']
            })
            this.onGetCreaDetail()
        });
    }

    onGetCreaDetail() {
        Promise.resolve(detail_crea(this.state.user_id, this.state.apikey, this.state.crea_id)).then((value) => {
            this.setState({
                crea_details: value[0],
                crea_status: true
            })
        });

    }


    render() {
        if (this.state.goBack === true) {
            return (<Redirect to={{
                pathname: '/app/creativities/task'
            }} />)
        }
        return (
            <div style={{ height: "99.8vh", overflowX: 'hidden', overflowY: 'auto' }}>
                <img
                    alt="back"
                    src={back}
                    style={{ width: 40, height: 40, marginTop: '0px', marginLeft: '0px', cursor: 'pointer', position: 'absolute' }}
                    onClick={() => {
                        this.setState({ goBack: true })
                    }}
                />
                <Row>
                    <Col span={24}>
                        <Tabs style={{ background: '#eee' }} defaultActiveKey='1'>
                            <TabPane
                                tab={
                                    <div style={{ width: '500px', textAlign: 'center', height: 15 }}>
                                        <Row>
                                            <Col span={3} style={{ textAlign: 'left' }}><img alt="Html" src={back} style={{ width: 35, height: 35 }} onClick={() => {this.setState({goBack: true})}}/></Col>
                                            <Col span={21} style={{ textAlign: 'center', padding: 3}}><span style={{ color: '#29B6F6', fontSize: 19 }}><img alt="Html" src={html} style={{ width: 35, height: 35 }} /><strong>Code</strong></span></Col>

                                        </Row>
                                    </div>
                                }

                                key="1"
                            >
                                {
                                    this.state.crea_status && <Html
                                        creativities={this.state.crea_details}
                                        crea_id={this.state.crea_id}
                                        liste_user={this.state.liste_user}
                                        liste_tags={this.state.liste_tags}
                                    />
                                }

                            </TabPane>

                            <TabPane
                                tab={
                                    // <div style={{ width: '500px', textAlign: 'center' }}>
                                    //     {/* <Tag color="cyan" style={{ fontSize: 16 }}>ROUTER</Tag> */}
                                    //     <img alt="Routeur" src={routeur} style={{width: 40, height: 40}}/>
                                    // </div>
                                    <div style={{ width: '500px', textAlign: 'center', height: 25 }}>
                                        <Row>
                                            <Col span={12} style={{ textAlign: 'right' }}><img alt="Routeur" src={routeur} style={{ width: 35, height: 35 }} /> </Col>
                                            <Col span={12} style={{ textAlign: 'left', padding: 9 }}><span style={{ color: '#57565C', fontSize: 19 }}><strong>Router</strong></span></Col>

                                        </Row>
                                    </div>
                                }

                                key="2"
                            >
                                {
                                    this.state.crea_status && <RouterDetails
                                        creativities={this.state.crea_details}
                                        crea_id={this.state.crea_id}
                                        liste_user={this.state.liste_user}
                                        liste_tags={this.state.liste_tags}
                                        liste_base_user={this.state.liste_base_user}
                                    />
                                }
                            </TabPane>

                            <TabPane
                                tab={
                                    // <div style={{ width: '500px', textAlign: 'center' }}>
                                    //     {/* <Tag color="purple" style={{ fontSize: 16 }}>STATS</Tag> */}
                                    //     <img alt="Focus" src={focus} style={{width: 35, height: 35}}/>
                                    // </div>
                                    <div style={{ width: '500px', textAlign: 'center', height: 25 }}>
                                        <Row>
                                            <Col span={12} style={{ textAlign: 'right' }}><img alt="focus" src={focus} style={{ width: 35, height: 35 }} /> </Col>
                                            <Col span={12} style={{ textAlign: 'left', padding: 2 }}><span style={{ color: '#e9967a', fontSize: 19, fontWeight: 800, }}><strong>Focus</strong></span></Col>

                                        </Row>
                                    </div>
                                }
                                key="3"
                            >
                                 {
                                    this.state.crea_status && <StatsDetails 
                                    creativities={this.state.crea_details} 
                                    crea_id={this.state.crea_id}
                                    liste_user={this.state.liste_user}
                                    liste_tags={this.state.liste_tags}
                                    liste_base_user={this.state.liste_base_user}
                                    liste_country={this.state.liste_country}
                                />
                                }
                            </TabPane>
                        </Tabs>
                    </Col>

                </Row>

            </div>
        );
    }
}


export default Details;