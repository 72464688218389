
export const base64_decode = (str)=>{
    try {
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    } catch (error) {
        return 'error decoding data'
    }
    
}

export const base64_encode = (str)=>{
    try {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
    } catch (error) {
        return 'error decoding data'
    }
 
}

export const base64EncodeUnicode = (str) => {
    try {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));  
    } catch (error) {
        return 'error decoding data'
    }
    
}

export const generateKey = () => {
    try {
        var length = 10
        var result = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    } catch (error) {
        return 'xyz'
    }
    
}